import React from "react";
import * as S from './VerticleStepper.styles';
import StepTemplate from "./StepTemplate";

const Step = props => (
  <>
    <S.Title>
      Step 4:
    </S.Title>
    <StepTemplate
      nextBtn="true"
      backBtn="true"
      text={`A first draft will be provided within 3/4 working days`}
      {...props}
    >
    </StepTemplate>
  </>
);

export default Step;
