import React from "react";
import Clients from "../Components/Clients/Clients";
import Description from "../Components/Description/Description";
import Panel from "../Components/Panel/Panel";
import Process from "../Components/Process/Process";
import Quadrant from "../Components/Quadrant/Quadrant";
import Services from "../Components/Services/Services";
import Showcase from "../Components/Showcase/Showcase";
import CV from "../Images/CV.png";

function Home() {
	return (
		<div>
			<Showcase />
			<Description />
			<Panel />
			<Services />
			<Process />
			<Quadrant />
			<Clients />
		</div>
	);
}

export default Home;
