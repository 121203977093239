import React from "react";
import * as S from './VerticleStepper.styles';
import StepTemplate from "./StepTemplate";

const Step = props => (
  
  <StepTemplate
      text={``}
      backBtn="false"
      nextBtn="true"
    {...props}
    >
    <S.Title>
      Step 1:
    </S.Title>
    <p>Contact us and provide info about your background / years of experience and current role</p>
  </StepTemplate>
);

export default Step;
