import React from "react";
import "./App.css";
import Home from "./Routes/Home";
import ContactUs from "./Routes/ContactUs";
import Services from "./Routes/Services";
import AboutUs from "./Routes/AboutUs";
import Blog from "./Routes/Blog";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";

function App() {
	return (
		<div>
			<Navbar />
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/ContactUs" element={<ContactUs />} />
				<Route exact path="/AboutUs" element={<AboutUs />} />
				<Route exact path="/Services" element={<Services />} />
				<Route exact path="/Blog" element={<Blog />} />
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
