import styled from "@emotion/styled";

export const Wrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	background: #fff;
	font-family: Roboto;

	p {
		text-align: center;
		font-size: 18px;
		margin-bottom: 20px;
	}

	.MuiCollapse-wrapperInner {
		max-width: 580px;
	}
	.MuiStepLabel-root {
		width: 100%;
	}
	.MuiStepLabel-label {
		font-size: 18px;
	}
	.MuiSvgIcon-root {
		font-size: 18px;
	}
	.MuiStepIcon-text {
		font-size: 20px;
	}
	.MuiStepIcon-root.MuiStepIcon-active {
		color: #77bc3f;
	}
	.MuiButton-containedPrimary {
		background-color: #77bc3f;
		font-size: 16px;
	}

	.MuiButton-label {
		font-size: 16px;
	}

	.MuiStepIcon-root.MuiStepIcon-completed {
		color: #77bc3f;
	}

	.MuiStepper-vertical {
		background-color: #fff;
    width: 520px;
	}

	@media (max-width: 768px) {
		.MuiButton-label {
			font-size: 14px;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	background: #fff;
	p {
		text-align: center;
		font-size: 18px;
		margin-bottom: 20px;
	}

	.MuiCollapse-wrapperInner {
		max-width: 600px;
	}
	.MuiStepLabel-root {
		width: 650px;
	}
	.MuiStepLabel-label {
		font-size: 18px;
	}
	.MuiSvgIcon-root {
		font-size: 2rem;
	}
	.MuiStepIcon-text {
		font-size: 1rem;
	}
	.MuiStepIcon-root.MuiStepIcon-active {
		color: #77bc3f;
	}
	.MuiButton-containedPrimary {
		background-color: #77bc3f;
	}

	.MuiStepIcon-root.MuiStepIcon-completed {
		color: #77bc3f;
	}
`;

export const Img = styled.img`
	display: inline-block;
	height: 100px;
	width: 20%;
	margin-right: 20px;
`;

export const Title = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 45px !important;
	line-height: 59px;
	text-align: center;

	color: #77bc3f;

	@media (max-width: 768px) {
		font-size: 25px !important;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StepContainer = styled.div`
	max-width: 600px;
`;
