import React from "react";
import * as S from './VerticleStepper.styles';
import StepTemplate from "./StepTemplate";

const Step = props => (
  <>
    <S.Title>
      Step 2:
    </S.Title>
    <StepTemplate
      backBtn="true"
      nextBtn="true"
      text={`
      A quote will be sent for acceptance 
      `}
      {...props}
    >
  </StepTemplate>
</>
);

export default Step;
