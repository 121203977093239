import React from "react";
import "./Description.css";
import Laptop from "../../Images/Laptop.png";

const Description = () => {
  return (
    <>
      <div className="description">
        <div className="imageDiv">
          <img src={Laptop}></img>
        </div>

        <div className="bio">
          <h3>We have completed over 1000 local and international CVs'.</h3>
          <p>
            The Digi Bar is a South African CV writing consultancy providing
            quality drafting, coupled with an effective turnaround time, to
            ensure your journey will be accurately conveyed.<br></br>
            <br></br> We will ensure that your CV is instantly noticed by both
            employers and recruiters.
          </p>
        </div>
      </div>
    </>
  );
};

export default Description;
