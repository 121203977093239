import React from "react";

function AboutUs() {
	return (
		<div>
			<h3> About Us Page</h3>
		</div>
	);
}

export default AboutUs;
