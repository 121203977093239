import React from "react";

function ContactUs() {
	return (
		<div>
			<h3> Contatc Us Page</h3>
		</div>
	);
}

export default ContactUs;
