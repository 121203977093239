import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as S from './VerticleStepper.styles';

const StepTemplate = ({
  canGoBack,
  onBack,
  onNext,
  text,
  children,
  backBtn,
  nextBtn,
  //we pass down these 2 values
  disabledNext,
  checkDisabledNext
}) => (
  <Fragment>
    <Typography>{text}</Typography>
      <S.StepContainer>
        {children}
        <S.ButtonContainer>
          {backBtn === "true" && <Button
            disabled={!canGoBack}
            onClick={onBack}
          >
            Back
          </Button>
          }
          {nextBtn === "true" && <Button
            variant="contained"
            color="primary"
            onClick={onNext}
            disabled={checkDisabledNext ? disabledNext : false}
          >
            {canGoBack ? "Next" : "go to next step"}
          </Button>
          }
        </S.ButtonContainer>
      </S.StepContainer>
  </Fragment>
);

export default StepTemplate;
