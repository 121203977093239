import React from "react";
import * as S from './VerticleStepper.styles';
import StepTemplate from "./StepTemplate";

const Step = props => (
  <>
    <S.Title>
      Step 3:
    </S.Title>
    <StepTemplate
      nextBtn="true"
      backBtn="true"
      text={`
        Request for details including old CVs(if available), a picture and job aspirations.
      `}
      {...props}
    >
    </StepTemplate>
  </>
);

export default Step;
