import React from "react";
import "./Process.css";
import VerticalStepper from "../VerticleStepper/VerticleStepper";

const Process = () => {
	return (
		<div className="process" id="process">
			<div style={{ textAlign: "center" }}>
				<h3>Our Process</h3>
			</div>

			<VerticalStepper />

			{/* <div className="blocks">
				<div className="block green">
					<div className="innerblock">
						<p>1.</p>
						<p>
							The client reaches out providing their
							background / years of experience / current
							role.
						</p>
					</div>
				</div>
				<div className="block grey">
					<div className="innerblock">
						<p>2.</p>
						<p>A quote will be sent and accepted.</p>
					</div>
				</div>
				<div className="block green">
					<div className="innerblock">
						<p>3.</p>
						<p>
							The client will need to give details
							including old CVs, a picture and job
							aspirations.
						</p>
					</div>
				</div>
				<div className="block grey">
					<div className="innerblock">
						<p>4.</p>
						<p>
							3-4 working days later, the client can
							review.
						</p>
					</div>
				</div>
				<div className="block green">
					<div className="innerblock">
						<p>5.</p>
						<p>
							Terms &amp; conditions say that payment is
							due once documents are completed.
						</p>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default Process;
