import React from "react";
import "./Clients.css";
import Laptop from "../../Images/Laptop.png";
import Carousel from "../Carousel/Carousel";

const Clients = () => {
	return (
		<div className="clients">
			<div className="header">
				<h3>What our clients have to say</h3>
				<p>Opportunities we have helped create for others</p>
			</div>
			<Carousel />
		</div>
	);
};

export default Clients;
