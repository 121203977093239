import React, { useState, useRef } from "react";
import CV from "../../Images/CV.png";
import "./Showcase.css";
import Arrow from "../../Images/Arrow.png";
import Laptop from "../../Images/Laptop.png";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import emailjs from "@emailjs/browser";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //maxWidth: "500px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    fontfamily: "Georgia",
    padding: "20px",
    width: "100%",
    maxWidth: "500px",
    background: "#f4f7f8",
  },
  area: {
    width: "100%",
    background: "rgba(255,255,255,.1)",
    border: "none",
    borderRadius: "4px",
    fontSize: "15px",
    outline: "0",
    padding: "10px",
    margin: "1em auto",
    boxSizing: "border-box",
    backgroundColor: "#e8eeef",
    color: "#8a97a0",
  },
  submit: {
    color: "#FFF",
    margin: "1em auto",
    background: "#77bc3f",
    fontSize: "18px",
    textAlign: "center",
    fontStyle: "normal",
    width: "100%",
    border: "1px solid #16a085",
    marginBottom: "10px",
    padding: "15px",
    cursor: "pointer",
  },
}));

const Showcase = () => {
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(false);
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm(); // initialise the hook
  const form = useRef();
  const onSubmit = async () => {
    emailjs
      .sendForm(
        "service_gkx50q6",
        "template_30taas3",
        form.current,
        "FJ6BfHhJ85rQsPV_i"
      )
      .then(
        (result) => {
          alert('Enquiry Submitted Successfully!')
        },
        (error) => {
          console.log(error.text);
        }
      );

    setOpen(false);
  };

  return (
    <div
      className="showcase"
      style={{
        backgroundImage: `url(${CV})`,
      }}
    >
      <div className="slogan">
        <h1>
          CV Writing with <br /> a difference!
        </h1>
        <p>We provide excellent and affordable CVs'.</p>
        <div className="flexDiv">
          <p
            className="quote"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Get in touch <img src={Arrow}></img>
          </p>
          <p className="quote download">
            <a href="Digibar.pdf" target="_blank">
              Company Profile
            </a>{" "}
            <img src={Arrow}></img>
          </p>
        </div>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          className={classes.modal}
        >
          <form
            ref={form}
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
          >
            <input
              className={classes.area}
              name="name"
              placeholder="Name"
              type="text"
              ref={register({
                required: true,
              })}
            />
            {errors.name &&
              (errors.name.message ? errors.name.message : "name is required.")}
            <input
              className={classes.area}
              name="email"
              placeholder="Email"
              type="email"
              ref={register({
                pattern: {
                  message: "Valid email please",
                },
                required: true,
              })}
            />
            <input
              style={{ height: "80px" }}
              className={classes.area}
              name="message"
              placeholder="Message"
              type="text"
              ref={register({
                required: true,
                pattern: {
                  message: "Enter a valid name please",
                },
                required: false,
              })}
            />
            {errors.message &&
              (errors.message.message
                ? errors.message.message
                : "message is required.")}
            <input className={classes.submit} type="submit" value="Send" />
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default Showcase;
