import React from "react";
import "./Carousel.scss";
import lebo from "../../Images/lebo.png";
import mbali from "../../Images/mbali.png";
import sah from "../../Images/sah.png";
import byron from "../../Images/byron.png";
import steph from "../../Images/steph.png";
import Next from "../../Images/right1.png";
import Prev from "../../Images/left1.png";

const slideWidth = 30;

const _items = [
	{
		player: {
			title: "Lebohang",
			desc: "Trade Mark Attorney, Adams & Adams SA",
			image: lebo,
			testimonial:
				'"I received a professional and sophisticated looking CV from Mishkah. The turnaround time was really impressive and she was willing to assist me with any amendments that I needed to make. I would highly recommend her services."',
		},
	},
	{
		player: {
			title: "Sahil",
			desc: "Chief Operating Officer - Connect to Customer, FNB SA",
			image: sah,
			testimonial:
				'"My CV was old, dusty and poorly structured. Mishkah took my CV, analysed my LinkedIn profile, had a meeting to understand me, where I am and what my needs were, and took this away to bring back an amazingly well structured professional CV. This made me want to hire me!"',
		},
	},
	{
		player: {
			title: "Byron",
			desc: "Director of Studies, EF - China",
			image: byron,
			testimonial:
				'"Mishkah was thorough when providing assistance and delivered the results in a very timely manner. I thought Mishka took time to understand my current role and what future role I was looking for and tailored this into creating a personalised CV and cover letter that would highlight the necessary attributes needed in order to make my CV and cover letter unique to others"',
		},
	},
	{
		player: {
			title: "Mbali",
			desc: "Banking & Finance Lawyer, Webber Wentzel",
			image: mbali,
			testimonial:
				'"Looking for new career-development opportunities can often be daunting, especially when you have an outdated CV that does not even get you to the interview stage of the recruitment process. It is thus no surprise that I was thrilled to come across The Digi Bar`s profile on LinkedIn. I was under pressure to circulate an updated CV and Mishkah delivered an excellent CV and cover letter within two days at a very reasonable cost."',
		},
	},
	{
		player: {
			title: "Stephanie",
			desc: "Regulatory Manager, Massmart",
			image: steph,
			testimonial:
				'"Thank you for capturing my CV in a way that I have never been able to. It was very well written and brings about the best about my comprehensive career in compliance. There 3 top things that stand out for me with your services are:" The time and effort you take to understand your clients career aspirations, The quick turnaround time, Your professionalism, I will definitely be recommending your services to my network."',
		},
	}, {
		player: {
			title: "Mbali",
			desc: "Banking & Finance Lawyer, Webber Wentzel",
			image: mbali,
			testimonial:
				'"Mishkah`s services are refreshingly exceptional. She is highly professional, a pleasure to work with and her writing is crisp and offers effortless reading. She was also able to succinctly illustrate the nuances of my career aspirations in an excellent cover letter. Mishkah`s tips on interviews, which can be found on LinkedIn, have been very useful. I unreservedly recommend The Digi Bar and Mishkah to anyone who is currently looking for career prospects"',
		},
	},
];
const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position, idx) => {
	const item = {
		styles: {
			transform: `translateX(${position * slideWidth}rem)`,
		},
		player: _items[idx].player,
	};

	switch (position) {
		case length - 1:
		case length + 1:
			item.styles = {
				...item.styles,
			};
			break;
		case length:
			break;
		default:
			item.styles = { ...item.styles, opacity: 0 };
			break;
	}

	return item;
};
const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
	const item = createItem(pos, idx, activeIdx);

	return (
		<li className="carousel__slide-item" style={item.styles}>

			<div className="carousel-slide-item__body">
				<p>{item.player.testimonial}</p>
				<div style={{display: 'flex', justifyContent: 'center', margin: '20px'}}>
					<img src={item.player.image} alt={item.player.title} />
					<h4>{item.player.title}</h4>
				</div>
				<div>{item.player.desc}</div>
			</div>
		</li>
	);
};
const keys = Array.from(Array(_items.length).keys());

const Carousel = () => {
	const [items, setItems] = React.useState(keys);
	const [isTicking, setIsTicking] = React.useState(false);
	const [activeIdx, setActiveIdx] = React.useState(0);
	const bigLength = items.length;

	const prevClick = (jump = 1) => {
		if (!isTicking) {
			setIsTicking(true);
			setItems((prev) => {
				return prev.map((_, i) => prev[(i + jump) % bigLength]);
			});
		}
	};

	const nextClick = (jump = 1) => {
		if (!isTicking) {
			setIsTicking(true);
			setItems((prev) => {
				return prev.map(
					(_, i) => prev[(i - jump + bigLength) % bigLength]
				);
			});
		}
	};

	const handleDotClick = (idx) => {
		if (idx < activeIdx) prevClick(activeIdx - idx);
		if (idx > activeIdx) nextClick(idx - activeIdx);
	};

	React.useEffect(() => {
		if (isTicking) sleep(300).then(() => setIsTicking(false));
	}, [isTicking]);

	React.useEffect(() => {
		setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
	}, [items]);

	return (
		<div className="carousel__wrap">
			<div className="carousel__inner">
				<div className="carousel__container">
					<ul className="carousel__slide-list">
						{items.map((pos, i) => (
							<CarouselSlideItem
								key={i}
								idx={i}
								pos={pos}
								activeIdx={activeIdx}
							/>
						))}
					</ul>
				</div>
				<div className="buttons">
					<button
						className="carousel__btn carousel__btn--prev"
						onClick={() => prevClick()}
					>
						<i />
						<img
							src={Prev}
							className="carousel__btn-arrow carousel__btn-arrow--left prev"
						></img>
					</button>
					<button
						className="carousel__btn carousel__btn--next"
						onClick={() => nextClick()}
					>
						<i />
						<img
							src={Next}
							className="carousel__btn-arrow carousel__btn-arrow--right next"
						></img>
					</button>
				</div>

				{/* <div className="carousel__dots">
					{items.slice(0, length).map((pos, i) => (
						<button
							key={i}
							onClick={() => handleDotClick(i)}
							className={
								i === activeIdx ? "dot active" : "dot"
							}
						/>
					))}
				</div> */}
			</div>
		</div>
	);
};

export default Carousel;
