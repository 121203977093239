import React from "react";
import "./Quadrant.css";
import LinkIn from "../../Images/LinkedIn.png";
import Legal from "../../Images/Legal.png";
import LinkedInLogo from "../../Images/linkedLogo.png";
import recruiterLogo from "../../Images/recruiterLogo.png";

const Quadrant = () => {
  return (
    <div className="quadrant">
      <div className="section">
        <div className="grey">
          <div className="infosec">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ width: "40px" }}
                src={LinkedInLogo}
                alt="linkedIn"
              />
            </div>
            <h3>Linkedin Revamps</h3>
            <p>
              We understand that it's difficult to write about yourself,
              <br></br>
              you may not be able to capture the full picture and tell your
              story tactfully.
              <br></br>
              <br></br> With us, we strategically develop your LinkedIn profile
              to showcase your value, skills and convey your credibility to
              compel attention.<br></br>
              <br></br> Don't ignore LinkedIn at the corporate level.<br></br>
              Prospecting employers are looking at your LinkedIn profile and
              forming opinions.
              <br></br> We help you take control and create an elevated profile
              that promotes you.
              <br></br>
              <br></br>
              It's time to create your narrative that aligns with your dreams
              and true potential. We help you to develop strategic,
              goal-oriented LinkedIn profiles and bios so you can move forward
              empowered and ready for the next chapter of your career.
            </p>
          </div>
        </div>
        <div
          className="imageWrapper"
          style={{
            backgroundImage: `url(${LinkIn})`,
          }}
        ></div>
      </div>

      <div className="section section2">
        <div
          className="imageWrapper"
          style={{
            backgroundImage: `url(${Legal})`,
          }}
        ></div>
        <div className="grey">
          <div className="infosec">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ width: "40px" }}
                src={recruiterLogo}
                alt="recruitment"
              />
            </div>
            <h3>Legal Recruitment</h3>
            <p>
              The Digi Bar provides legal recruitment services to meet the
              national and worldwide hiring demands of the legal profession.
              <br></br>
              <br></br>
              Due to our enormous network and wealth of knowledge, we have been
              able to represent a wide range of clients, including the renowned
              Big 5 law firms in South Africa, international law firms, as well
              as boutique law firms. <br></br>
              <br></br>
              We have successfully served international clients in New York and
              helped with head-hunting projects while also offering advisory
              services to legal firms such as Dentons and ENS Africa.<br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quadrant;
