import React from "react";
import "./Panel.css";
import People from "../../Images/People.png";
import Consult from "../../Images/Consult.png";
import Expert from "../../Images/Expert.png";
import Free from "../../Images/Free.png";
import Payment from "../../Images/Payment.png";
import Personal from "../../Images/Personal.png";
import Rapid from "../../Images/Rapid.png";

const Panel = () => {
	return (
		<div>
			<div
				className="panel"
				style={{
					backgroundImage: `url(${People})`,
				}}
			>
				<div className="info">
					<h3>Did you know?</h3>
					<p>
						Recruiters spend on average 5-7 seconds
						looking at a CV.
					</p>
					<p>
						Your CV is an important tool for your job search.
						It displays your top skills <br /> and qualities
						and what makes you stand out from the crowd.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Panel;
