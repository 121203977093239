import React from "react";

function Blog() {
	return (
		<div>
			<h3> Blog Page</h3>
		</div>
	);
}

export default Blog;
