import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepButton from "@material-ui/core/StepButton";
import * as S from "./VerticleStepper.styles";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const VerticalStepper = () => {
	const [activeStep, setActiveStep] = useState(0);

	const steps = {
		"Contact Us": Step1,
		Quote: Step2,
		Refinement: Step3,
		"Processing...": Step4,
		"Your CV is ready": Step5,
	};

	const stepsCount = () => Object.values(steps).length;

	const canGoBack = () => activeStep > 0;
	const canGoForward = () => activeStep < stepsCount();
	// const isFinished = () => activeStep === stepsCount();

	const handleBack = () => {
		if (canGoBack()) {
			setActiveStep(activeStep - 1);
		}
	};

	const handleNext = () => {
		if (canGoForward()) {
			setActiveStep(activeStep + 1);
		}
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};
	// const handleReset = () => setActiveStep(0);

	return (
		<S.Wrapper>
			<Stepper
				nonLinear
				activeStep={activeStep}
				orientation="vertical"
			>
				{Object.entries(steps).map(([label, CustomStep], i) => (
					<Step key={label}>
						<StepButton
							color="inherit"
							onClick={handleStep(i)}
						>
							{label}
						</StepButton>
						<StepContent>
							<CustomStep
								canGoBack={canGoBack}
								canGoForward={canGoForward}
								onBack={handleBack}
								onNext={handleNext}
							/>
						</StepContent>
					</Step>
				))}
			</Stepper>
		</S.Wrapper>
	);
};

export default VerticalStepper;
