import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo from "../../Images/Logo.svg";
import SAlogo from "../../Images/salogo.png";

const Footer = () => {
	return (
		<div className="footer">
			<a href="/">
				<img src={Logo} className="logo"></img>
			</a>
			<img style={{ width: '100px', marginRight: 'auto', marginLeft: '2%' }} src={SAlogo} className="SAlogo"></img>
			<nav>
				<ul>
					<li><a href="tel:0769078411">+27 76 907 8411</a>&nbsp;&nbsp;&nbsp;&nbsp;</li>
					<li><a href="mailto:info@digibar.co.za">info@digibar.co.za</a></li>
				</ul>
			</nav>
		</div>
	);
};

export default Footer;
