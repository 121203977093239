import React from "react";
import "./Services.css";
import People from "../../Images/People.png";
import Consult from "../../Images/Consult.png";
import Expert from "../../Images/Expert.png";
import Free from "../../Images/Free.png";
import Payment from "../../Images/Payment.png";
import Personal from "../../Images/Personal.png";
import Rapid from "../../Images/Rapid.png";

const Services = () => {
	return (
		<div>
			<div id="services" className="services">
				<h3>Our Services</h3>
				<div className="servicepanel">
					<div className="servicecircles">
						<img src={Consult}></img>
						<p>
							Convenient online <br />
							consultations
						</p>
					</div>
					<div className="servicecircles">
						<img src={Personal}></img>
						<p>Personalized services</p>
					</div>
					<div className="servicecircles">
						<img src={Rapid}></img>
						<p>Rapid delivery</p>
					</div>
				</div>

				<div className="servicepanel">
					<div className="servicecircles">
						<img src={Payment}></img>
						<p>
							Safe and secure <br /> payment
						</p>
					</div>
					<div className="servicecircles">
						<img src={Expert}></img>
						<p>
							Comprehensive sector <br /> expertise
						</p>
					</div>

					<div className="servicecircles">
						<img src={Free}></img>
						<p>
							Free, no-obligation <br /> CV review service
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Services;
