import React from "react";
import * as S from "./VerticleStepper.styles";
import StepTemplate from "./StepTemplate";

const Step = (props) => (
  <>
    <S.Title>Step 5:</S.Title>
    <StepTemplate
      nextBtn="false"
      backBtn="true"
      text={`
      Final draft provided and payment occurs
      `}
      {...props}
    ></StepTemplate>
  </>
);

export default Step;
